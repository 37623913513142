import React from "react"

import './button.module.scss'

const Button = ({ children, link, color, onClick }) => {
  if (link) {
    return(
      <a
      onClick={onClick}
      href={link}
      className={`button ${color ? color : 'gray'}`}>
        {children}
      </a>
    )
  }
  else if (onClick) {
    return (
      <button
        onClick={onClick}
        className={`button ${color ? color : 'gray'}`}>
        {children}
      </button>
    )
  } else {
    return (
      <span
        className={`button ${color ? color : 'gray'}`}>
        {children}
      </span>
    )
  }
}

export default Button
