import React, { useState } from 'react';
import { graphql, useStaticQuery } from "gatsby"

import Button from "../components/button"
import CollapsingCard from "../components/CollapsingCard"
import Layout from "../components/layout"
import Seo from "../components/seo"


const ActualitePage = () => {
  const [state, setState] = useState({
    filterByCategory: undefined
  });

  const data = useStaticQuery(graphql`
    query getPageBlog {
      wpPage(slug: {eq: "actualite"}) {
        id
        title
        content
        slug
        featuredImage {
        node {
            imageCustomFields {
              position
            }
            localFile {
              publicURL
            }
          }
        }
      }

      allWpCategory(filter: {count: {gt: 0}}) {
        categories: nodes {
          id
          name
          slug
        }
      }

      allWpPost(sort: {fields: date, order: DESC}) {
        posts: nodes {
          id
          title
          content
          slug
          uri
          date(formatString: "Do MMMM YYYY", locale: "fr")
          featuredImage {
            node {
              imageCustomFields {
                position
              }
              altText
              mediaDetails {
                sizes {
                  name
                  sourceUrl
                }
              }
            }
          }
          categories {
            nodes {
              name
              slug
            }
          }
        }
      }
    }
  `)

  if (!data) return null

  const {
    title,
    content,
    featuredImage
  } = data.wpPage

  const {
    categories,
  } = data.allWpCategory

  const {
    posts,
  } = data.allWpPost

  const filterPosts = (e, categorySlug) => {
    if (state.filterByCategory === categorySlug) {
      setState({ filterByCategory: undefined })
    } else {
      setState({ filterByCategory: categorySlug })
    }
    e.preventDefault();
  }


  return (
    <Layout featuredImage={featuredImage}>
      <Seo title={title} />
      <div className="container">
        <div className="row">
          <div className="column-left">
            <div className="sticky-top">
              <h1>{title}</h1>
              <div className="categories-filter">
                {
                  categories.map(function (category) {
                    return (
                      <Button
                        link={`#${category.slug}`}
                        key={category.id}
                        color={state.filterByCategory === category.slug ? 'black' : 'grey'}
                        onClick={(e) => { filterPosts(e, category.slug) }}
                      >
                        {category.name}
                      </Button>
                    );
                  })
                }
              </div>
            </div>
          </div>
          <div className="column-right mt-negative">
            <div dangerouslySetInnerHTML={{ __html: content }}></div>
            <div>
              {
                posts.map(function (post) {
                  return (
                    <CollapsingCard
                    key={post.id}
                    content={post.content}
                    title={post.title}
                    subtitle={post.date}
                    featuredImage={post.featuredImage && post.featuredImage.node}
                    isOpened={
                      post.categories.nodes.length === 0 & state.filterByCategory === undefined ? true :
                        post.categories.nodes.some(
                          (category) => {
                            if (state.filterByCategory) {
                              return category.slug === state.filterByCategory;
                            } else {
                              return category;
                            }
                          }
                        )
                    }></CollapsingCard>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ActualitePage;
